import { Menu } from './nav.service';

import { BaseService } from 'app/shared/services/base/base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiObjectData } from '../model/apiObjectData';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  controller = 'menu';

  constructor(private base: BaseService) { }

  get(id): Observable<ApiObjectData> {
    return this.base.get(id, this.controller);
  }

  gets(): Observable<ApiObjectData> {
    return this.base.gets(this.controller);
  }

  save(obj: Menu) {
    return this.base.save(this.controller, obj);
  }

  remove(id: number) {
    return this.base.remove(this.controller, id);
  }

  getByUser(id): Observable<ApiObjectData> {
    return this.base.getByUser(id, this.controller);
  }
}
