export class ConfigDB {
  static data = {
    settings: {
      layout_type: 'rtl',
      sidebar: {
        type: 'default',
        body_type: 'default'
      },
      sidebar_setting: 'border-sidebar',
      sidebar_backround: 'dark-sidebar'
    },
    color: {
      layout_version: 'light',
      color: 'color-2',
      primary_color: '#0288d1',
      secondary_color: '#26c6da',
      mix_layout: 'dark-header-sidebar-mix'
    },
    router_animation: 'fadeIn'
  };
}
