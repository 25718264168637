import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as screenfull from 'screenfull';
import config from 'devextreme/core/config';
import { AuthService } from './shared/services/firebase/auth.service';
import { BaseService } from './shared/services/base/base.service';
import { User } from './shared/model/user';
import { locale, loadMessages } from 'devextreme/localization';
import arMessages from '../assets/data/devextreme/ar.json';
import { Router } from '@angular/router';
import { ConfigService } from './shared/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ERP SYSTEM';
  jwtHelper = new JwtHelperService();
  isFullscreen = false;
  constructor(private configService: ConfigService,
              private authService: AuthService,
              private baseService: BaseService,
              private router: Router
  ) {
    config({ rtlEnabled: true, forceIsoDateParsing: true });
  }

  ngOnInit() {
    localStorage.clear();
    loadMessages(arMessages);
    locale('ar');
    this.baseService.dir = 'rtl';
    this.baseService.lang = 'ar';
    this.baseService.isRTL = true;
    localStorage.setItem('dir', 'rtl');
    localStorage.setItem('lang', 'ar');
    const token = localStorage.getItem('token');
    const user: User = JSON.parse(localStorage.getItem('user'));
    if (token) {
      this.authService.decodedToken = this.jwtHelper.decodeToken(token);
    }
    if (user) {
      this.authService.currentUser = user;
    }
  }
}

