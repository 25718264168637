import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule, Provider } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { ToastrModule } from "ngx-toastr";
import { AuthService } from "./shared/services/firebase/auth.service";
import { AdminGuard } from "./shared/guard/admin.guard";
import { SecureInnerPagesGuard } from "./shared/guard/SecureInnerPagesGuard.guard";
import { CookieService } from "ngx-cookie-service";
import { JwtConfig, JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { BlockUIModule } from "ng-block-ui";
import { ConfigService } from "./shared/services/config.service";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function tokenGetter() {
  return localStorage.getItem("token");
}

export function jwtConfigFactory(): JwtConfig {
  return {
    tokenGetter,
    allowedDomains: [],
    disallowedRoutes: [],
  };
}

export function jwtInitialiserFactory(
  configService: ConfigService,
  jwtConfig: JwtConfig
): () => Promise<void> {
  return async () => {
    await configService.loadConfig();
    jwtConfig.allowedDomains.push(configService.config.baseUrl);
    jwtConfig.disallowedRoutes.push(configService.config.baseUrl + "/api/auth");
  };
}

const jwtOptionsProvider: Provider = {
  provide: JWT_OPTIONS,
  useFactory: jwtConfigFactory,
  deps: [],
};

const jwtInitialiserProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: jwtInitialiserFactory,
  deps: [ConfigService, JWT_OPTIONS],
  multi: true,
};

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    BlockUIModule.forRoot(),
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({ jwtOptionsProvider }),
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter,
    //     allowedDomains: [configService.config.url],
    //     disallowedRoutes: [this.configService.config.url + '/api/auth']
    //   }
    // }),
  ],
  providers: [
    AuthService,
    AdminGuard,
    SecureInnerPagesGuard,
    CookieService,
    jwtInitialiserProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
