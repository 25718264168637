import { MenuService } from 'app/shared/services/menu.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BaseComponent } from 'app/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'app/shared/services/base/base.service';
import { AlertService } from 'app/shared/services/base/alert.service';
import { AuthService } from 'app/shared/services/firebase/auth.service';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

  public newUser = false;
  user: any = {};
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    email: '',
    password: '',
  };
  public errorMessage: any;

  constructor(public authService: AuthService,
           private fb: FormBuilder,
              private router: Router, private alertService: AlertService,
              public baseService: BaseService, private menuService: MenuService) {
    super();
    this.loginForm = fb.group({
      userName: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  // Login With Google
  // loginGoogle() {
  //   this.authService.GoogleAuth();
  // }

  // // Login With Twitter
  // loginTwitter(): void {
  //   this.authService.signInTwitter();
  // }

  // // Login With Facebook
  // loginFacebook() {
  //   this.authService.signInFacebok();
  // }

  // Simple Login
  onSubmit() {
    this.baseService.blockStart();
    this.authService.login(this.user).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        const user = data;
        if (user) {
          localStorage.setItem('token', user.token);
          localStorage.setItem('user', JSON.stringify(user.user));
          this.authService.decodedToken = this.authService.jwtHelper.decodeToken(user.token);
          this.authService.currentUser = user.user;
        }
        this.alertService.success('تم تسجيل الدخول بنجاح');
      },
      error => {
        this.baseService.blockStop();
        this.alertService.error(error.error.content);
        console.log(error.error.log);
      },
      () => {
        // this.router.navigateByUrl('/dashboard/default');
        this.getUerMenu();
      }
    );
    this.loginForm.reset();
  }

  // login() {
  //   this.authService.SignIn(this.loginForm.value.email, this.loginForm.value.password);
  // }


  getUerMenu() {
    const id = JSON.parse(localStorage.getItem('user')).id;
    this.menuService.getByUser(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.baseService.blockStop();
        localStorage.setItem('routeMenuItems', JSON.stringify(data.returnData));
      },
      error => {
        this.baseService.blockStop();
        console.log(error);
      },
      () => {
        this.router.navigateByUrl('/page/home');
        // if (localStorage.getItem('routeMenuItems').indexOf('/page/dashboard') !== -1) {
        //   this.router.navigate(['/page/dashboard']);
        // } else {
        //   this.router.navigate(['/page/home']);
        // }
      }
    );
  }

}
